/* eslint-disable max-len */
import {
  RISK_SECTION_HIGH,
  RISK_SECTION_LOW,
  RISK_SECTION_MEDIUM,
  RISK_SECTION_VERY_LOW,
} from '@consts/risk';
import {
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL } from '@consts/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_VERY_LOW, 'common.very_low', 'risk.very_low', 0, 0),
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 1, 3),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 4, 99, 0, 3),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 4, 99, 4, 99),
];

export const resultSettings = [
  formatResult(
    'Det verkar inte som att du löper risk att utveckla långvariga besvär. Om behovet uppstår kan du kontakta en fysioterapeut via Joint Academy.',
    '',
    0,
    0,
  ),
  formatResult(
    'Även mindre ryggbesvär kan vara tufft och det går att undvika långvariga besvär med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Enligt dina testsvar verkar du ha lättare besvär av din ryggsmärta och din risk för att utveckla långvariga problem bedöms som låg. Däremot är det alltid bra att genomföra en utredning i god tid, och få hjälp för att undvika att besvären förvärras eller kommer tillbaka. Med rätt behandling kan du också påskynda din återhämtning. Ett nästa steg för dig kan vara att söka vård för att få en bedömning.<br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för ryggsmärta. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    1,
    3,
  ),
  formatResult(
    'Envis smärta i ryggen kan vara tufft men det går att minska besvären med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Baserat på dina testsvar verkar du besväras av din ryggsmärta i vardagen och risken för att utveckla långvariga besvär bedöms som måttlig. Det kan vara bra för dig att genomföra en bedömning av legitimerad vårdpersonal, och få hjälp för att undvika att besvären förvärras. Med rätt behandling kan du också påskynda din återhämtning.<br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för ryggsmärta. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    4,
    99,
    0,
    3,
  ),
  formatResult(
    'Avsevärd smärta i ryggen kan vara tufft, men det går att minska besvären med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Baserat på dina testsvar verkar du ha mycket besvär av din ryggsmärta och risken för att utveckla långvariga besvär bedöms som hög. Det kan vara bra för dig att genomföra en bedömning av legitimerad vårdpersonal, och få hjälp för att undvika att besvären förvärras. Med rätt behandling kan du också påskynda din återhämtning.<br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för ryggsmärta. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    4,
    99,
    4,
    99,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Har din ryggsmärta strålat ut längs dina ben vid något tillfälle under de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question2',
    'Har du haft ont i axeln eller nacken vid något tillfälle under de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question3',
    'Går du bara korta sträckor på grund av din ryggsmärta?',
  ),
  formatYesNoQuestion(
    'question4',
    'Har det under de senaste två veckorna tagit längre tid än vanligt för dig att klä på dig på grund av ryggsmärtor?',
  ),
  formatYesNoQuestion(
    'question5',
    'Tror du att det kan vara skadligt för en person med dina besvär att vara fysiskt aktiv?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question6',
    'Har du haft regelbundna oroande tankar den senaste tiden?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question7',
    'Känner du att din ryggsmärta är fruktansvärd och att den aldrig kommer att bli bättre?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question8',
    'Har du i allmänhet slutat glädja dig över de saker som du brukar glädja dig åt?',
    1,
    0,
    1,
  ),
  formatQuestion(
    'question9',
    'Sammantaget, hur besvärlig har din ryggsmärta varit de senaste två veckorna?',
    [
      formatOption('Inte alls', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Lätt', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Måttligt', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Väldigt mycket', 1, 1, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Extremt', 1, 1, COL_SIZE_FULL, COL_SIZE_FULL),
    ],
  ),
];

export const references = [
  'Hill JC, Dunn KM, Lewis M, et al. A primary care back pain screening tool: identifying patient subgroups for initial treatment. Arthritis Rheum. May 15 2008;59(5):632-641.',
  'Hill JC, Dunn KM, Main CJ, Hay EM. Subgrouping low back pain: a comparison of the STarT Back Tool with the Orebro Musculoskeletal Pain Screening Questionnaire. Eur J Pain. Jan 2010;14(1):83-89.',
  'Hill JC, Vohora K, Dunn KM, Main CJ, Hay EM. Comparing the STarT back screening tool’s subgroup allocation of individual patients with that of independent clinical experts. Clin J Pain. Nov-Dec 2010;26(9):783-787.',
  'Hill JC, Whitehurst DG, Lewis M, et al. Comparison of stratified primary care management for low back pain with current best practice (STarT Back): a randomised controlled trial. Lancet. Oct 29 2011;378(9802):1560-1571.',
  'Fritz JM, Beneciuk JM, George SZ. Relationship between categorization with the STarT Back Screening Tool and prognosis for people receiving physical therapy for low back pain. Physical therapy. May 2011;91(5):722-732.',
];
